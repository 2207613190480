import React from 'react';
import { Box, Text, Container } from 'theme-ui';
import { keyframes } from '@emotion/core';
import logo from 'assets/dominus-logo.png';
import bannerIcon1 from 'assets/lightbulb.svg';
import bannerIcon2 from 'assets/lightning.svg';
import bannerIcon4 from 'assets/cable.svg';
import bannerIcon5 from 'assets/plug.svg';
import SEO from 'components/seo';


const bannerAnim1 = keyframes`
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
`;

const bannerAnim2 = keyframes`
  0% {
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    transform: translateY(30px) translateX(30px) rotate(15deg);
    transform-origin: center center;
  }
  50% {
    transform: translateY(50px) translateX(50px) rotate(45deg);
    transform-origin: right bottom;
  }
  80% {
    transform: translateY(30px) translateX(30px) rotate(15deg);
    transform-origin: left top;
  }
  100% {
    transform: translateY(0px) translateX(0) rotate(0);
    transform-origin: center center;
  }
`;

export default function UnderConstructionPage() {
  return (
    <>
      <SEO title="Dominus electricity | Under Construction" />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          bg: 'background',
          p: 4,
          position: 'relative',
        }}
      >
        <Box
          as="img"
          src={bannerIcon1}
          alt="Lightbulb Icon"
          sx={{
            position: 'absolute',
            top: '10%',
            left: '10%',
            width: '40px',
            animation: `${bannerAnim2} 8s linear infinite`,
          }}
        />
        <Box
          as="img"
          src={bannerIcon2}
          alt="Lightning Icon"
          sx={{
            position: 'absolute',
            top: '10%',
            right: '10%',
            width: '40px',
            animation: `${bannerAnim2} 8s linear infinite`,
          }}
        />
        <Box
          as="img"
          src={bannerIcon4}
          alt="Cable Icon"
          sx={{
            position: 'absolute',
            bottom: '20%',
            left: '20%',
            width: '40px',
            animation: `${bannerAnim1} 5s ease-out infinite`,
          }}
        />
        <Box
          as="img"
          src={bannerIcon5}
          alt="Plug Icon"
          sx={{
            position: 'absolute',
            bottom: '20%',
            right: '20%',
            width: '40px',
            animation: `${bannerAnim1} 8s linear infinite`,
          }}
        />

        <Box as="img" src={logo} alt="Dominus Electricity Logo" sx={{ width: '150px', mb: 4 }} />
        <Text as="h1" sx={{ fontSize: 5, color: 'text', mb: 3 }}>
          Trenutno nema napajanja... ali radimo na tome! ⚡️
        </Text>
        <Text as="p" sx={{ fontSize: 3, color: 'textSecondary', mb: 3 }}>
          Naš sajt je trenutno u izradi i uskoro će zasijati.
        </Text>
      </Container>
    </>
  );
}
